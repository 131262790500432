.assistantOuter, .userOutter {
    background: transparent;
    display: flex;
    padding: 10px;
    width: 100%;
    font-size: .92rem;
    box-sizing: border-box;
}

.assistantOuter {
    justify-content: left;
}

.userOutter {
    justify-content: right;
}

.Assistant {
    color: black;
    margin-left: 3px;
    padding: 0 8px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-top-left-radius: 0;
    background-color: beige;
    max-width: 75%;
    position: relative;
    word-wrap: break-word;
    top: 15px;
}

.AssistantCopyIcon, .UserCopyIcon {
    cursor: pointer;
}

.AssistantCopyIcon {
    color: var(--navColor1);
    float: right;
}

.UserCopyIcon {
    margin-top: 8px;
    color: beige;
    float: left;
}

.User {
    color: black;
    padding: 8px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-top-right-radius: 0;
    background-color: var(--navColor1);
    max-width: 75%;
    position: relative;
    word-wrap: break-word;
    top: 15px;
    box-sizing: border-box;
}

/* @media (max-width: 900) {
    .assistantOuter, .userOutter {
        font-size: .9rem;
    }
} */