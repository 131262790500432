.CardContainer {
    box-sizing: border-box;
    border-radius: 15px;
    margin: 10px auto;
    /* max-width: 250px; */
    width: 100%;
    padding: 0;
    background-color: white;
    transition: all 0.2s ease-in-out;
    box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    overflow: hidden;
}

.CardContainer:hover {
    transform: scale(1.05);
}

.CardContainer hr {
    border: 3px solid var(--navColor1);
    margin: 0;
    border-radius: 15px;
}

.CardTop {
    background-color: var(--lightBlueGrey);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 100%;
    border-bottom: 3px solid var(--navColor1);
}

.PriceTag {
    box-sizing: border-box;
    background-color: var(--navColor1);
    border-radius: 20px;
    color: white;
    font-weight: 700;
    font-size: 1.4rem;
    height: 100px;
    line-height: 1.4;
    width: 75%;
    margin: -50px auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 20px 70px 500px rgba(0, 0, 0, 0.25), 
                0 10px 10px rgba(0, 0, 0, 0.5);
}

.CardBottom {
    padding: 15px 5px;
}

@media (max-width: 900px) {
    .CardContainer {
        margin-bottom: 30px;
    }
}