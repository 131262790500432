.OulineGreen {
    padding: .5rem 2rem;
    border-radius: 50px !important;
    border: 2px solid var(--lightModeGreen);
    color: var(--lightModeGreen) !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.LogOut {
    padding: .5rem 2rem;
    border-radius: 50px !important;
    border: 2px solid var(--lightModeGreen);
    color: var(--lightModeGreen) !important;
    cursor: pointer;
    margin-top: -10px;
    font-size: 18px !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.ButtonSubmitBlack, .ButtonTransparent  {
    padding: 0 1.7rem;
    border: 1px solid black;
    border-radius: 50px !important;
    box-sizing: border-box;
    cursor: pointer;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.ButtonOutlineGreenWithDiffStyle {
    background-color: white;
    border: 1px solid var(--navColor1);
    color: var(--navColor1);
}

.ButtonOutlineGreenWithDiffStyle:hover {
    box-shadow: none;
    box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
}

.ButtonSubmitGreen, .ButtonOutlineGreenWithDiffStyle {
    border-radius: 50px !important;
    box-sizing: border-box;
    cursor: pointer;
    font-size: .9rem;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.ButtonSubmitGreen {
    background-color: var(--navColor1);
    border: none;
    color: white;
    box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
    -webkit-box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 3px 16px -8px rgba(0,0,0,0.75);
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.ButtonSubmitGreen:before {
    border-radius: 50px !important;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, transparent, var(--navColor3), transparent);
    background-size: 200% 200%;
    background-position: -100% 0;
    animation: ripple 2s ease-in-out infinite;
    z-index: -1;
}

@keyframes ripple {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.ButtonSubmitGreen:hover {
    box-shadow: none;
}

.ButtonSubmitBlack {
    background-color: black;
    color: white;
}

.ButtonSubmitBlack:hover {
    background-color: rgba(0, 0, 0, 0.685);
}

.ButtonTransparent {
    background-color: rgba(255, 255, 255, 0.329);
    color: black;
}

.ButtonTransparent:hover {
    background-color: rgb(255, 255, 255);
}

.ButtonTransparentSquare  {
    border: none;
    background: #f3f0f1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    box-shadow: -6px 0px 10px rgba(255, 255, 255, 0.8),
                    6px 0px 10px rgba(0, 0, 0, 0.2);
    
} 

.ButtonTransparentSquare:hover  {
    opacity: 0.3;
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8),
      6px 6px 10px rgba(0, 0, 0, 0.2);
}

.ButtonTransparentSquare:active  {
    opacity: 1;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
      inset 8px 8px 16px rgba(0, 0, 0, 0.1); 
}

.AdminButtonCard  {
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    background: aliceblue;
    display: flex;
    flex-direction: column;
    line-height: .5;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
    cursor: pointer;
    padding: 20px 0;
    transition: all 0.4s ease-in-out;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5),
    inset 8px 8px 16px rgba(0, 0, 0, 0.1);
} 

.AdminButtonCard:active  {
    opacity: 0.9;
    box-shadow: -1px -1px 5px rgba(255, 255, 255, 0.8),
      6px 6px 5px rgba(0, 0, 0, 0.2); 
}

.ButtonCard {
    background-color: var(--navColor2);
    border-radius: 6px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    width: 70%;
    min-width: 350px;
    margin: 20px auto;
    text-align: center;
    transition: all 0.3s ease-in;
    padding: 20px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
}

.ButtonCard:hover {
    box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
}


.ButtonCard hr {
    width: 30px;
}

.ButtonThin {
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    font-size: .8rem;
    font-weight: 500;
    /* max-width: 22px; */
}

/* Smaller Screens */
@media (max-width: 900px)  {
    .ButtonSubmitBlack {
        padding: 0 1rem;
    }

    .ButtonCard {
        margin: 20px auto;
        width: 100%;
    }
}

