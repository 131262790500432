  
.circle {
    background: #CFDDD5;
    box-sizing: border-box;
    width: 70%;
    height: 80%;
    margin: 0 auto;
    border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    overflow: hidden;
    -webkit-box-shadow: 6px 3px 17px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 3px 17px 0px rgba(0,0,0,0.75);
    box-shadow: 6px 3px 17px 0px rgba(0,0,0,0.75);
    animation: morph 3s linear infinite;
    -webkit-border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    -moz-border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    -ms-border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    -o-border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
    -webkit-animation: morph 3s linear infinite;
}


.welcome-links {
    width: 90%;
    height: 90%;
    text-decoration: none !important;
}
  
.circle img {
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
}

.circle:hover img{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.circle p {
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.288);
    color: #fff;
    font-size: .8rem;
    font-weight: 400;
    position: relative;
    top: -9rem;
    border-radius: 10px;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.circle:hover p {
    background-color: #03cafca1;
    transform: scale(1.1);
    padding: 5px 25px 0;
    height: 101%;
    top: -16rem;
    font-weight: 700;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}


@keyframes morph{
    0% { 
        border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%; 
        -webkit-border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%; 
        -moz-border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%; 
        -ms-border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%; 
        -o-border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
    }
    34% {
        border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        -webkit-border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        -moz-border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        -ms-border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
        -o-border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        -webkit-border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        -moz-border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        -ms-border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        -o-border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    }
}




/* Smaller Screens */
@media (max-width: 800px)  {

    .circle {
        width: 300px;
    }

}