.ResumeInnerHeader {
    color: white !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.showOptions, .hideOptions {
    box-sizing: border-box;
    cursor: pointer;
    font-size: .8rem;
    font-weight: 500;
    z-index: 9999;
    transition: all .5s ease-in-out;
}

.showOptions {
    background-color: inherit;
    display: flex;
}

.hideOptions {
    color: var(--navColor2);
    position: fixed;
}


@media (max-width: 1024px) {
    .ResumeInnerHeader {
        font-size: .9rem;
    }
}