.AdminSideMenu {
    background-color: aliceblue;
    border-radius: 12px;
    position: fixed;
    top: 20px;
    bottom: 20px;
    padding: 20px 10px;
    width: 250px;   
    transition: all 0.1s ease-in; 
    margin-right: 20px;
    border: 2px solid var(--lighterBlueGrey);
}

.Logo {
    width: 50%;
    height: 50px;
    padding-left: 10px;
    margin-bottom: 20px;
}

.SideMenuItems {
    margin: 0;
    padding-left: 0;
}

.SideMenuItems li {
    box-sizing: border-box;
    border-radius: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.454);
    list-style-type: none;
    height: 3rem;
    margin: 2px 0;
    font-size: .93rem;
    font-weight: 600;
    transition: all .4s ease-out;
    width: 100%;
}

.SideMenuItems li:hover {
    background-color: #3E8F93;
}


.newFeat {
    background-color: var(--navColor1);
    width: 60px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: snow;
    font-size: .7rem;
    font-weight: 500;
    transition: all .4s ease-out;
}

.SideMenuItems li:hover .newFeat {
    background-color: white !important;
    color: var(--navColor1) !important;
    border-radius: 20px;
}

.Active {
    background-color: #3E8F93;
    border-radius: 30px;
}

.Active > a {
    color: white !important;
}

.SideMenuItems li:hover > a {
    color: white !important;
}

.SideMenuItems li > a > span {
    margin-left: 20px;
}

.SideMenuItems li > a {
    color: rgb(51, 49, 49);
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: 7px;
}

.side-menu-minimized {
    transition: all 0.1s ease-out;
    width: 60px;
    overflow-x: hidden;
}
