.authContainer {
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    padding: 40px 0 0;
    width: 100vw;
    height: 110vh;
    z-index: 1;
}

.formContainer {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.formInner {
    background-color: rgba(255, 255, 255, 0.927);
    border-radius: 10px;
    width: 480px;
    text-align: center;
    line-height: 1;
    margin-top: 150px;
    margin-bottom: 50px;
    padding: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.582);
    -webkit-box-shadow: 4px 3px 14px 1px rgba(0,0,0,0.75);
    -moz-box-shadow: 4px 3px 14px 1px rgba(0,0,0,0.75);
}

/* .formInner h2 {
    color: var(--lightModeGreen);
} */

.pwdRec {
    color: var(--lightModeGreen) !important;
    cursor: pointer;
    position: relative;
    top: -20px;
    right: 10px;
    float: right;
    font-size: .9rem !important;
    font-weight: 700 !important;
}


.regOnLoginPage {
    color: var(--lightModeGreen) !important;
    cursor: pointer;
    position: relative;
    top: -20px;
    left: 10px;
    float: left;
    font-size: .9rem !important;
    font-weight: 700 !important;
}

.enabledResendBtn, .disabledResendBtn {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: auto;
    margin: 0 5px;
    width: 100px;
    text-align: center;
    border: none;
}

.enabledResendBtn {
    background-color: var(--navColor1);
    color: #FFF;
}

.enabledResendBtn:hover {
    background-color: var(--navColor3);
}

.disabledResendBtn {
    background-color: #c0d1d453;
    cursor: no-drop;
}


/* Smaller Screens */
@media (max-width: 900px)  {
    .authContainer {
        flex-direction: column;
    }

    .formInner {
        margin-top: 150px;
        width: 95%;
    }
}