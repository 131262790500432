
.DiscountHeader {
    color: #F6F4EC;
    background-color: #272727;
    font-size: .9rem;
    padding: 15px;
    top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100vw;
    position: fixed;
    z-index: 99
}

.TitleContainer {
    background-image: linear-gradient(45deg, var(--snow), var(--lighterBlueGrey));
    box-shadow: 0px 5px 11px 7px rgba(0,0,0,0.29); 
    height: 50vh;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    flex-direction: column;
    padding: 0 10px;
}

.TitleContainer h1 {
    font-family: "Bubblegum Sans", sans-serif;
}

.TitleContainer h5 {
    font-family: "Bubblegum Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem !important;
    margin: -10px auto 10px;
}

.ShowOffCont {
    background-color: #E8E8E8;
    border-radius: 15px;
    color: #161616;
    width: 90%;
    margin: auto;
    padding: 15px;
    text-align: center;
}

.ShowOffCont h2 {
    text-align: center;
}

.ShowOffBody {
    width: 100%;
    height: 400px;
}

.ShowOffImg {
    width: 100%;
    height: 100%;
}

.TopCompanies {
    color: white;
    text-align: center;
    background-color: black;
    width: 100vw;
    padding: 50px 10px 90px;
    border-radius: 30px;
    margin-bottom: 40px;
}

.TopCompanies h2 {
    margin-bottom: 25px;
    font-size: 2.5rem;
}

.TopCompanies h2 {
    margin-bottom: 25px;
}

.CompanyImg {
    width: 50%;
    height: 50px;
}

.Process {
    margin: 30px 20px;
}

.Process h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-align: center
}

.Process h3 {
    font-family: "Bubblegum Sans", sans-serif;
    font-size: 2rem;
    margin-bottom: 0;
}

.Points {
    margin: 0 20px;
    line-height: 1.2;
}

.Pointers {
    color: var(--navColor1);
    margin: 0 13px;
    font-size: 1.2rem;
}

.faqItem {
    cursor: pointer;
    color: #FFF;
    display: flex;
    height: 45px;
    justify-content: space-between;
    background: linear-gradient(to right, rgba(63, 106, 108, 0.9), rgba(63, 106, 108, 0.178));
    margin: 0 0 10px 0;
    padding: 0 20px;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    align-items: center
  }

.faqAnswer {
    overflow: hidden;
    margin-left: 5%;
    width: 90%;
    height: 0;
    transition: 'all 0.3s ease'
}

.faqClicked {
    color: #000;
    font-size: .9rem;
    width: 90%;
    margin-left: 5%;
    opacity: 1;
    height: auto;
    margin-bottom: 10px;
    transition: all 0.3s ease
}

.plus {
    font-size: 20px;
    color: #000
}


@media (max-width: 900px) {
    .TitleContainer {
        height: 60vh;
    }

    .ShowOffCont {
        width: 100%;
    }

    .faqAnswer {
        font-size: .9rem;
    }

    .faqClicked {
        color: #000;
        font-size: .9rem;
    }

    .CompanyImg {
        margin-bottom: 20px;
        width: 70%
    }
}