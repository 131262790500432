@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Edu+AU+VIC+WA+NT+Hand:wght@400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Rubik+Bubbles&display=swap');


:root {
  --primaryColor: #fff;
  --lightModeGreen: #6FCBD1;
  --navColor1: #3E8F93;
  --navColor2: #56A8AC;
  --navColor3: #5fbec5;
  --navColor4: #7CC9CC;
  --navColor5: #99E1E4;
  --snow: rgb(255, 250, 250);
  scroll-behavior: smooth;
  --lightBlueGrey: #c0d1d4;
  --lighterBlueGrey: #c0d1d457;
}

* {
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  margin: 0;
  color: #333333;
  background: white;
  font-family: Inter, sans-serif;
  padding: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}


h1 {
  font-size: 2.2rem;
}

h5 {
  font-size: 1rem !important;
}

.link {
  color: rgb(177, 144, 13);
  text-decoration: none;
}

.description-box {
  background-color: #c0d1d457;
  box-sizing: border-box;
  padding: 6px;
  border-radius: 5px;
  margin: 10px auto;
  width: 96%;
  text-align: center;
  font-size: .8rem;
  font-weight: 500;
  color: #333333;
  box-shadow: outset 10px 10px 10px rgba(0, 0, 0, 0.1)
}


.error, .success-mini {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  font-size: .8rem;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 200px;
  max-width: 300px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  padding: 10px;
  z-index: 10000000;
  box-shadow: -2px 0px 10px rgba(132, 129, 129, 0.5), 
  6px 0px 10px rgba(0, 0, 0, 0.1);
}

.error {
  color: rgb(216, 7, 7);
}

.success-mini {
  color: var(--navColor1);
}

.error-in {
  opacity: 1;
}

.success {
  font-size: .8rem;
  color: rgb(216, 7, 7);
  margin: 10px auto;
  text-align: center;
}

.authLogo {
  width: 90px;
}

.add, .delete {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  padding-bottom: 3px;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.add {
  color: var(--lightModeGreen);
  border: 1.5px solid var(--lightModeGreen);
}
.delete {
  color: rgba(216, 7, 7, 0.493);
  border: 2px solid rgba(216, 7, 7, 0.493);
}

.segment {
  padding: 15px;
  border: 3px solid #4b939892;
  border-radius: 10px;
  margin-bottom: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.array-item {
  background-color: var(--lightModeGreen);
  border-radius: 50px;
  box-sizing: border-box;
  color: black;
  display: flex;
  align-items: center;
  padding: 1px 0 1px 8px;
  font-size: .8rem;
  font-weight: 600;
  margin: 10px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.itemDelete {
  color: rgb(216, 7, 7);
  cursor: pointer;
  border-left: 1px solid white;
  margin-left: 10px;
  padding: 5px 10px;
}


.auth-bg-blob {
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  background-color: #4b939892;
  top: -86%;
  left: -19%;
  width: 80% !important;
  height: 140vh;
  padding: 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  transform: rotate(-18deg);
  -webkit-transform: rotate(-18deg);
  -moz-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  -o-transform: rotate(-18deg);
  z-index: -999;
}

.auth-container {
  box-sizing: border-box;
  width: 100vw !important;
  height: auto;
  padding: 0;
  margin: 0;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.auth-container-inner {
  box-sizing: border-box;
  padding: 10px 20px;
}

.container-inner a, .chatbg-overlay a {
  color: var(--lightModeGreen) !important;
  cursor: pointer;
  font-size: .9rem !important;
  font-weight: 700 !important;
} 

.BodyWrapper {
  box-sizing: border-box;
  border-radius: 15px;
  background-color: var(--snow);
  margin-top: 20px;
  padding: 0;
  width: 100%;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  box-shadow: 0px 12px 11px -7px rgba(0,0,0,0.29);
  -webkit-box-shadow: 0px 12px 11px -7px rgba(0,0,0,0.29);
  -moz-box-shadow: 0px 12px 11px -7px rgba(0,0,0,0.29);
}

.BodyWrapper form, .BodyWrapper .content {
  margin: 2rem 0;
  padding: 10px 5px;
}

.BodyWrapper h4 {
  color: var(--navColor2);
  text-align: center;
}

.BuildNavigator {
  background-color: #c0d1d4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;   
  padding: 0;
  height: 50px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
}

.BuildNavigator div {
  cursor: pointer;
  color: rgb(42, 42, 42);
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100%/2);
  height: 100%;
  font-size: .8rem;
  font-weight: 600;
}

.BuildNavigator div>span {
  background-color: rgba(255, 255, 255, 0.767);
  display: flex;
  font-size: .7rem;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.ActiveNav {
  background-color: var(--navColor2);
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 10px 2px 5px -4px rgba(0,0,0,0.29);
  -webkit-box-shadow: 10px 2px 5px -4px rgba(0,0,0,0.29);
  -moz-box-shadow: 10px 2px 5px -4px rgba(0,0,0,0.29);
}

.Segment {
  padding: 10px;
  margin: 20px 0;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100% !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px; 
  box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
}

.Segment h5 {
  font-size: .9rem !important;
}

.Faded {
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: -1;
  transform: scale(0.4);
  transition: all 0.3s ease-out;
}

.Faded-in {
  opacity: 1;
  transform: scale(1);
  z-index: 1000;
  height: auto;
  transition: all 0.8s ease-in;
}


.explanation-points {
  margin: 0;
  font-size: .8rem !important;
}

.go-back {
  width: 380px;
  position: relative;
  color: white;
  z-index: 999;
}

.prev-page {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  font-size: .9rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 20px;
  margin-top: 12px;
  color: var(--navColor1);
  box-shadow: -6px 0px 10px rgba(255, 255, 255, 0.8),
              6px 0px 10px rgba(0, 0, 0, 0.2);
}

.align-right-bold {
  font-weight: 600;
  margin-top: 3px;
}

.detached-label {
  font-size: .7rem;
  margin-top: -10px;
  text-align: left;
  margin-left: 10px
}



.bubble-text {
  font-family: "Rubik Bubbles", system-ui;
  font-weight: 800;
  position: relative;
  display: inline-block;
  animation: bubble-size 4s infinite linear;
}

.bubble-text span {
  position: relative;
  z-index: 1;
  display: inline-block;
}

@keyframes bubble-size {
  0% {
    font-size: 10px;
    color: var(--navColor5)
  }
  50% {
    font-size: 2.6rem;
    color: var(--navColor1);
  }
  100% {
    font-size: 10px;
    color: var(--navColor1);
  }
}


.custom-checkbox {
  font-size: .6rem;
}

.custom-checkbox input[type="checkbox"] {
  font-size: .6rem;
}

.custom-checkbox .MuiCheckbox-root {
  font-size: .6rem;
}

.custom-checkbox .MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--navColor2);
}

.custom-checkbox .MuiTypography-root {
  font-size: .6rem;
}

/* For webkit-based browsers */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #c0d1d4;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
  border-radius: 5px;
}


audio::-webkit-media-controls-download-button {
  display: none !important;
}





@media (max-width: 924px) {
  .Logo {
    width: 90px !important;
  }

  .authLogo {
    width: 85px;
  }
}

/* ResizeObserver loop completed with undelivered notifications ERROR FIX */
iframe#webpack-dev-server-client-overlay{display:none!important}