.container {
    box-sizing: border-box;
    margin: 20px 0 0 0;
    min-width: 400px;
    width: 100vw;
    padding: 1.6rem 6rem;
}


.container-inner {
    box-sizing: border-box;
    padding: 0 3rem 0 2rem;
    border: 2px solid var(--lightModeGreen);
    border-radius: 15px;
    text-align: center;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}

.form-ask-anything {
    height: 80px;
    width: 100%;
    text-align: center;
    position: relative;
    top: 10px;
}

.askme-bg {
    background-image: url('../../images/chat-land.gif');
    background-size: cover;
    background-repeat: repeat;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;  
    padding: 0; 
    position: absolute;
    top: 0;
    bottom: 0;
}

.chat-ex {
    box-sizing: border-box;
    width: 100vw;
    height: 92%;
    padding-right: 0;
    padding-top: 3.5rem;
    padding-bottom: 3%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chatbg-overlay {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    margin: 0;
}

.ask-me-form {
    border-top: 2px solid #000;
    width: 100%;
    background-color: black;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: absolute;
    bottom: 0;
}


.categories {
    display: none;
}

.chat-header {
    background-color: black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    border-bottom: 1px solid #99E1E4;
    position: fixed;
    top: 0;
    z-index: 9999;
}

.chat-menu-icon {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}

.close-icon {
    background-color: rgba(255, 255, 255, 0.972);
    border-radius: 50%;
    color: black;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 10px;
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.suggestion-container {
    box-sizing: border-box;
    border: none;
    background: rgba(0, 0, 0, 0.646);
    /* box-shadow: -1px 0px 10px rgba(255, 255, 255, 0.8),
                    -1px 0px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 12px;
    margin-top: 15px;
    width: 91%;
    padding: 5px;
    position: fixed;
    text-align: center;
    top: 35px;
    transition: all 1s ease-in-out;
    z-index: 1;
}

.suggestion-container h5 {
    color: aliceblue;
    font-size: .7rem !important;
}

.suggestion-out {
    opacity: 0;
    transition: all 2s ease-in-out;
    transform: translateY(-200px);
}

.suggestion-buttons {
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 33%;
    background-color: rgba(0, 0, 0, 0.78);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    padding: 5px;
    margin: 5px 0 0;
}

.suggestion-buttons:hover {
    background-color: black;
    box-shadow: none;
}

.suggestion-buttons:nth-child(even) {
    float: right;
    margin-bottom: 5px;
}


.not-found {
    color: var(--navColor3) !important;
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* Smaller Screens */
@media (max-width: 900px)  {
    .container {
        padding: 1.5rem;
        margin-top: 0;
    }
    .container-inner {
        padding: 0 10px;
    }

    .chat-ex {
        height: 94%;
    }

    .askme-bg {
        max-height: 89vh;
        background-size: 58%;
        background-repeat: repeat;
        background-image: url('../../images/chat-portrait.gif');
    }

    .ask-me-form {
        bottom: -11px;
    }

    .suggestion-buttons {
        width: 70%;
    }

}
