.NewTranscript, .Transcript {
    margin: 5px auto;
    padding: 5px 15px;
    width: 100%;
}

.NewTranscript {
    background-color: #c0d1d4;
    margin-bottom: 20px;
}

.Transcript {
    background-color: #c0d1d443;
    border: 2px dashed #c0d1d4;
    font-size: .8rem;
}

.Icon {
    cursor: pointer;
    transition: all 0.3s ease-in;
    box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 1px 0px -1px rgba(0,0,0,0.75);
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    position: relative;
    z-index: 9999;
}

.Icon:hover {
    box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -1px 1px 44px -3px rgba(0, 0, 0, 0.16);
}
