.DragnDrop {
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    margin: auto;
    border: 4px dashed var(--navColor2);
    cursor: pointer;
}