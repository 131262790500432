.DetachedLabels {
    display: inline-flex;
    align-items: center;
    margin-left: 19px;
    height: .8rem;
    font-size: .7rem;
    font-weight: 500;
    color: #14225188;
    border-radius: 4px;
    padding: 1px 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.CenteredElem {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
}

.TemplateContainer {
    height: 200px;
    margin-bottom: 20px;
}

.carouselInner {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carouselInner h5 {
    color: var(--lightBlueGrey);
}

.carouselMiddle, .carouselMiddleActive {
    background-size: cover;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-sizing: border-box;
    color: var(--navColor2);
    cursor: pointer;
    display: flex;
    font-size: 3rem;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    transition: 0.5s all ease-in-out;
}

.carouselMiddle {
    border: 5px solid var(--lightBlueGrey);
}

.carouselMiddleActive {
    border: 5px solid var(--navColor2);
}


.ResponsivePrintView {
    background-color: white;
    width: 800px;
    box-shadow: outset;
    height: auto;
    margin: 20px auto;
    box-shadow: 0 0 0 1px gray, 0 0 10px gray;
    position: relative;
}                           

@media (max-width: 800px) {
    .ResponsivePrintView {
        width: 100%;
        box-shadow: outset;
        overflow-x: scroll;
        position: relative;
        height: auto;
    }
}


  
