.ContainerOpen {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    background-color: white;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 330px;
    padding: 85px 20px 20px;
    transition: all 0.3s ease-in-out;
    box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.56);
    -webkit-box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.56);
    -moz-box-shadow: 4px 0px 5px 0px rgba(0,0,0,0.56);
    z-index: 10;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.ContainerClose {
    position: absolute;
    width: 350px;
    left: -350px;
    height: 100vh;
}

.TopCont {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.TopCont h4 {
    margin-top: 0;
    margin-bottom: 0
}

.TopCont h1 {
    margin-top: 0;
}

.RightTopCont {
    height: 100%;
    width: 100px;
}

.LeftTopCont {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    line-height: 0.9;
    width: 180px;
    padding: auto
}


.InnerContainer {
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    padding: 10px 5px;
    width: 100%;
    height: 50%;
    margin-bottom: 20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow-y: scroll;
}

.Items {
    width: 100%;
    color: #3E8F93;
    padding: 10px;
}

.Item, .ItemActive {
    border-radius: 5px;
    cursor: pointer;
    height: 100px;
    margin-top: 1px;
    padding: 8px 8px;
    font-size: .85rem !important;
    box-shadow: -1px 5px 6px -3px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 5px 6px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 5px 6px -3px rgba(0,0,0,0.75);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.Item {
    background-color: #c0d1d4 !important;
    color: var(--navColor1) !important;
    transition: all 0.5s ease-in;
}

.ItemActive {
    background-color: var(--navColor1) !important;
    color: white !important;
    transition: all 0.3s ease-out;
}

.buildDate {
    color: gray;
    text-align: right;
    font-size: .6rem;
    width: 100%;
}

.ItemInnerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 998;
}

.ItemInnerBottom {
    border-radius: 5px;
    background-color: rgba(255, 250, 250, 0.625);
    cursor: copy;
    display: flex;
    align-items: center;
    margin: 2px auto;
    width: 100%;
    height: 40px;
    padding: 5px;
    z-index: 999;
    font-size: .6rem;
}

.UnavailableImg {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin: 0 auto 15px;
}

.NonMonthlySubDisplay {
    width: 100%;
    color: #3E8F93;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

