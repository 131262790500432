.BigMenu {
    width: 100vw;
    box-sizing: border-box;
    background-color: white;
    position: fixed;
    top: 0;
    margin: 0 ;
    padding: .8rem 4rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 999;
    padding: 1.6rem 6rem 0;
}

.regLoginLink {
  text-decoration: none;
  color: white !important; 
  width: 100%; 
  height: 100%; 
  display: flex; 
  align-items: center; 
  justify-content: center
}

.Logo {
  width: 10rem;
  height: 3.5rem;
}

.MenuItems {
  display: flex;
}

.ListItem {
  color: #333333 !important;
  font-weight: 500 !important;
  text-decoration: none !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 5px solid #b7604600;
  padding: 1.5rem 1rem;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

/* Smaller Screens */
@media (max-width: 900px)  {
  

  .BigMenu {
    display: none;
  }

  .SmallMenuContainer {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100vw;
    background-color: white;
    padding: 1.7rem 2rem;
  }

  .HamburgerContainer {
    position: fixed;
    top: 30px;
    right: 2.5rem;
    z-index: 999;
  }

  .small-menu-out {
    display: none;
  }


  /* Overlay */
  .overlay {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateX(-100vw);
  }

  .overlay-slide-right {
    transition: all 0.4s ease-in-out;
    transform: translateX(0);
  }

  .overlay-slide-left {
    transition: all 0.8s ease-in-out;
    transform: translateX(-100vw);
  }

  /* Nav Menu Items */
  nav,
  nav ul {
    height: 100vh;
    margin: 0;
    padding: 0;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    list-style: none;
  }

  nav ul li {
    height: 20%;
    overflow: hidden;
  }

  nav li a {
    position: relative;
    top: 45%;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    text-decoration: none;
    display: block;
    text-align: center;
  }

  .logout {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 4px;
    justify-content: center;
  }

  nav li a:hover {
    transform: scale(1.2);
  }



  nav li:nth-of-type(1) {
    background-color: var(--navColor1);
  }

  nav li:nth-of-type(2) {
    background-color: var(--navColor2);
  }

  nav li:nth-of-type(3) {
    background-color: var(--navColor3);
  }

  nav li:nth-of-type(4) {
    background-color: var(--navColor4);
  }

  nav li:nth-of-type(5) {
    background-color: var(--navColor5);
  }

  /* Slide in animation with delay for each */
  .slide-in-1 {
    animation: slide-in 0.4s linear 0.2s both;
  }

  .slide-in-2 {
    animation: slide-in 0.4s linear 0.4s both;
  }

  .slide-in-3 {
    animation: slide-in 0.4s linear 0.6s both;
  }

  .slide-in-4 {
    animation: slide-in 0.4s linear 0.8s both;
  }

  .slide-in-5 {
    animation: slide-in 0.4s linear 1s both;
  }

  @keyframes slide-in {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  /* Slide out animation with delay for each */
  .slide-out-1 {
    animation: slide-out 0.3s linear 0.5s both;
  }

  .slide-out-2 {
    animation: slide-out 0.3s linear 0.4s both;
  }

  .slide-out-3 {
    animation: slide-out 0.3s linear 0.3s both;
  }

  .slide-out-4 {
    animation: slide-out 0.3s linear 0.2s both;
  }

  .slide-out-5 {
    animation: slide-out 0.3s linear 0.1s both;
  }

  @keyframes slide-out {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }

}