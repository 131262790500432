.Mobile {
    box-sizing: border-box;
    border: none;
    width: 100% !important;
    margin: 0 auto;
    background-color: #F5F5F5 !important;
    border-radius: 50px !important;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    color: #14225188 !important;
    font-size: 16px !important;
    height: 2.2rem !important;
}


.Mobile:focus {
    outline: none;
    font-size: 16px !important;
    color: #142251 !important;
}

.stateCountryCss {
    border-radius: 50px !important;
    font-size: 16px !important;
    width: 100%;
    margin: auto;
    background-color: #F5F5F5;
    height: 2rem;
    margin-top: .16rem;
    padding: .3rem;
}




