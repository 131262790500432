.ModalContainer {
    background-color: #000000eb;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    padding: 100px 0 50px;
    overflow-y: scroll;
}


.ModalBody, .ModalBodyTwo {
    background-color: white;
    border-radius: 10px;
    width: 60%;
    height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
} 
.ModalBodyTwo {
    background-color: rgba(0, 0, 0, 0.044);
}

.ModalBlobBg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    position: relative;
}

.ModalInner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    text-align: center;
    padding: 30px;
    background: rgb(153,225,228);
    background: radial-gradient(circle, rgba(153,225,228,0.9114496140252977) 0%, rgba(62,143,147,1) 35%);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.CheckoutContainer, .PlainModalOverlay {
    background-color: var(--snow);
    box-sizing: border-box;
    width: 600px;
    padding: 20px 15px;
    margin: 0 auto;
    z-index: 9999;
    border-radius: 5px;
}

.CheckoutContainer h2 {
    text-align: center;
    margin-top: -2px;
}

.PlainModalOverlay {
    text-align: center;
    width: 500px;
}

.PlainModalOverlay h2 {
    color: var(--navColor2);
}

.PlainModalOverlay p {
    font-size: .85rem;
}

.SuccessFailureLogoWrap {
    width: 150px;
    margin: 20px auto 0;
    padding: 0; 
}

.CheckoutLogoWrapper {
    width: 100px;
    margin: 10px auto 0;
    padding: 0;
}

.CheckoutInnerContainerGroup, .CheckoutInnerContainer {
    background-color: var(--lighterBlueGrey);
    display: flex;
    font-size: .85rem;
    width: 100%;
}
.CheckoutInnerContainerGroup {
    flex-direction: column;
}

.CheckoutInnerContainer {
    justify-content: space-between;
    padding: 5px 10px;
}

.CancelCheckout {
    text-align: center;
    font-size: .7rem;
    text-decoration: underline;
    cursor: pointer;
}

.reviewCarousel {
    width: 100%;
    overflow-y: scroll;
    height: 90px;
    text-align: left;
    font-size: .67rem;
    border: 1px solid var(--lighterBlueGrey);
    border-radius: 10px;
    padding: 0 5px;
}

.Blinker {
    animation: blinker 1.8s linear infinite;
    width: 10rem;
    height: 3.5rem;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* Smaller Screens */
@media (max-width: 900px)  {

    .ModalBody {
        width: 75%;
    }

    .CheckoutContainer {
        width: 370px;
    }

    .PlainModalOverlay {
        width: 400px;
    }

}