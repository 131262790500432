.DescritionCont {
    border-radius: 5px;
    color: black;
    padding: 10px;
    background-color: #56A8AC;
    font-size: .9rem;
    margin: auto;
    margin-bottom: 2rem;
    width: 80vw;
    text-align: center;
}

